import React,{useState,useEffect} from 'react';
import './RandomDog.css';
function RandomDog() {
    const[dogImage,setDogImage]=useState('');
    useEffect(()=>{fetchRandomDogImage();},[]);

    const fetchRandomDogImage = async()=>{try{const response = await fetch('https://dog.ceo/api/breeds/image/random');
    
    const data = await response.json();
    setDogImage(data.message);}
    catch (error){
        console.error('error fetching dog image:',error);
    }
    };
    const handleButtonClick=()=>{
        fetchRandomDogImage();
    };
    return(
        <div className='container'>
            <h1>Random Dog</h1>
            <img className='img-dog' src={dogImage} alt="Dog Breed Image"/>
            <button onClick={handleButtonClick}>Get Random Dog Breed</button>
        </div>
    );    
}
export default RandomDog;