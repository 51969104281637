import React from 'react';

function Category ({onSelect}){
    return(
        <>
        <h2>Explore Random Images of all Breed of Dog,Cat And Birds</h2>
        
        {/* <button onClick={()=>onSelect('Dog')}>Dog</button> */}
        {/* <button onClick={()=>onSelect('Cat')}>Cat</button> */}
        {/* <button onClick={()=>onSelect('Bird')}>Bird</button> */}
        
        </>
    );
};
export default Category;