import React,{useState,useEffect} from "react";
function RandomFacts() {
    const[fact,setFact]=useState('');
    useEffect(()=>{
        const fetchRandomFacts = async ()=>{
            try{
                const response = await fetch('https://uselessfacts.jsph.pl/random.json');
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data= await response.json();
                setFact(data.text);
            
            }catch(error){
                console.error('Error Fetching Random Facts:',error);
            }
        };
        fetchRandomFacts();
        const interval =setInterval(fetchRandomFacts,10000);
        return()=> clearInterval(interval);
    },[]);
    return(
        <div className="facts-container">
            <h2>Random Animal Facts</h2>
            <p>{fact}</p>

        </div>
    );
}
    

export default RandomFacts;