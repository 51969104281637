import React from "react";
import './Footer.css';
function Footer() {
    return(
        <>
         <footer>
            <div className="conatainer">
                <p>&copy;Furry Facts. All Rights Reserved</p>

            </div>

         </footer>
        </>
    )
    
}
export default Footer;