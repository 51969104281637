import react,{useState,useEffect} from 'react';
import './RandomCat.css';
function RandomCat(){
    const[catImage,setCatImage]=useState('');

     const fetchRandomCatImage = async()=>{
        try{
            const response = await fetch('https://api.thecatapi.com/v1/images/search');
     const data = await response.json ();
     setCatImage(data[0].url);}
     catch (error) {
        console.error('error fetching Cat Image',error)
    }};
    useEffect(() => {
        fetchRandomCatImage();
      }, []); 
    const handleButtonClick=()=>{
     fetchRandomCatImage();
    };
    return(
        <div className="container">
            <h1>Random Cat</h1>
            <img src={catImage} alt="Random Cat Image" />
            <button onClick={handleButtonClick}>Get Random Cat Breed</button>
        </div>
    );
};
export default RandomCat;