import React,{useEffect,useState} from 'react';
import './RandomBird.css';
function RandBird() {
    const[BirdImage,setBirdImage]=useState('');
    const fetchRandomBird=async ()=>{
        try{
        const response =await fetch('https://source.unsplash.com/500x500/?bird');
        const imageUrl=response.url;
        setBirdImage(imageUrl)}
        catch (error){
            console.error('error fetching bird image',error);
        }};
        useEffect(()=>{
            fetchRandomBird();
        },[]);

        const handleButtonClick=()=>{
            fetchRandomBird();
        };
    return(
        <div className="container">
            <h1>Random Bird</h1>
            <img src={BirdImage} alt="Random Bird image" />
            <button onClick={handleButtonClick}>Get Random Bird Breed</button>
        </div>
    );
    
};
export default RandBird;