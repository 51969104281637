import CategorySelector from './components/CategorySelector';
import './App.css';
import RandomDog from './components/RandomDog';
import RandomCat from './components/RandomCat'; 
import RandomBird from './components/RandomBird';
import RandomFacts from './components/RandomFacts';
import Footer from './components/Footer.js';
import React, { useEffect, useState } from 'react';
import { Application } from '@splinetool/runtime';

function App() {
  const [category, setCategory] = useState('dog');
  const [isScrolled, setIsScrolled] = useState(false);

  const handleCategorySelect = (selectedCategory) => {
    setCategory(selectedCategory);   
  };
  
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50 && !isScrolled) {
        setIsScrolled(true);
      } else if (window.scrollY <= 50 && isScrolled) {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  useEffect(() => {
    const canvas = document.getElementById('canvas3d');
    if (canvas){
      const app = new Application(canvas);
      app.load('https://prod.spline.design/4ShL5hJ7roIpk-5T/scene.splinecode');
    }
  },[]);
  
  return (
    <div className={`App ${isScrolled ? 'scrolled' : ''}`}>
      <nav className={`navbar ${isScrolled ? 'scrolled' : 'transparent'}`}>
        <div className='logo'>FURRY FACTS</div>
        <div>
          <ul className='nav-list'>
            <li><a href="#" onClick={() => setCategory('dog')}>Dog</a></li>
            <li><a href="#" onClick={() => setCategory('cat')}>Cat</a></li>
            <li><a href="#" onClick={() => setCategory('bird')}>Bird</a></li>
          </ul>
        </div>
      </nav>
      <RandomFacts/>
      <CategorySelector onSelect={handleCategorySelect}/>
      {category === 'dog' && <RandomDog />}
      {category === 'cat' && <RandomCat />}
      {category === 'bird' && <RandomBird />}
      <canvas id='canvas3d' />
      <Footer/>
    </div>
  );
}

export default App;
